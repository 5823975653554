import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/normalize.css'
import './assets/css/webflow.css'
import './assets/css/zerg.webflow.css'
import './assets/css/normalize_1.css'
import './assets/css/webflow_1.css'
import './assets/css/zerg.webflow_1.css'
// import './assets/js/webflow_2.js'


const app = createApp(App)
app.use(router)
app.mount('#app')

// createApp(App).mount('#app')
