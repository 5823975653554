<template>
  <div v-if="screenWidth < 1000">
      <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" :class="{ 'menu-open': isMenuOpen }" class="navbar11_component background-color-black w-nav">
      <div class="navbar11_container">
        <a href="https://zerg.studio/" aria-current="page" class="navbar11_logo-link w-nav-brand w--current"><img src="@/assets/images/ZERG.svg" loading="lazy" alt="" class="navbar11_logo"></a>
        <nav ref="menuRef" role="navigation" :class="{ 'show-nav': isMenuOpen }" class="nav-menu w-nav-menu">
          <nav role="navigation" :class="{ 'nav-open': isMenuOpen, 'nav-close': !isMenuOpen }" class="navbar11_menu is-page-height-tablet w-nav-menu">
            <div :class="{ 'nav-links-container': isMenuOpen }">
              <!-- <a href="index.html" aria-current="page" class="navbar11_link w-nav-link w--current">Home</a> -->
              <a href="https://zerg.studio/" aria-current="page" :class="{ 'mobile-link': isMenuOpen }" class="navbar11_link w-nav-link">Home</a>
              <a href="https://zerg.studio/warriors" :class="{ 'mobile-link': isMenuOpen }" class="navbar11_link w-nav-link">Warriors</a>
              <a href="https://zerg.studio/lore" :class="{ 'mobile-link': isMenuOpen }" class="navbar11_link w-nav-link w--current">Lore</a>
              <a href="https://points.zerg.studio" :class="{ 'mobile-link': isMenuOpen }" class="navbar11_link w-nav-link">$WAR</a>
              <a href="https://reveal.zerg.studio" :class="{ 'mobile-link': isMenuOpen }" target="_blank" class="navbar11_link w-nav-link">Reveal</a>
              <a href="https://awrd.gg/zergcustoms" :class="{ 'mobile-link': isMenuOpen }" target="_blank" class="navbar11_link w-nav-link">Customs</a>
          </div>
          <div class="navbar11_menu-buttons">
                <a href="https://mint.zerg.studio" :class="{ 'mobile-button': isMenuOpen }" class="button is-secondary w-button">PURCHASE SIII</a>
              </div>
        </nav>
        </nav>
        <div @click="toggleMenu" class="navbar11_menu-button w-nav-button">
          <div class="menu-icon1">
            <div class="menu-icon1_line-top"></div>
            <div class="menu-icon1_line-middle">
              <div class="menu-icon_line-middle-inner"></div>
            </div>
            <div class="menu-icon1_line-bottom"></div>
          </div>
        </div>
        <!-- <div class="menu-button w-nav-button">
          <div class="w-icon-nav-menu"></div>
        </div> -->
      </div>
    </div> 
  </div>
  <div v-else>
      <NavBarFull></NavBarFull>
  </div>
</template>
<script>
import { ref, onMounted, onUnmounted } from "vue"
import NavBarFull from './NavBarFull.vue'
export default{
    name: 'NavBar',
    components:{
      NavBarFull
    },
    setup() {
      const isMenuOpen = ref(false);
      const screenWidth = ref(window.innerWidth)
      const menuRef = ref(null)
      const toggleMenu = (e) => {
        e.stopPropagation()
        isMenuOpen.value = !isMenuOpen.value;
      };
      const handleResize = () => {
        screenWidth.value = window.innerWidth
      }

      const handleBodyClick = (e) => {
        if (menuRef.value && !menuRef.value.contains(e.target)) {
          isMenuOpen.value = false;
        }
      };

      onMounted(() => {
        window.addEventListener('resize', handleResize);
        document.body.addEventListener('click', handleBodyClick);
      })

      onUnmounted(() => {
        window.removeEventListener('resize', handleResize);
        document.body.removeEventListener('click', handleBodyClick);
      })

      return { 
        isMenuOpen, 
        toggleMenu,
        screenWidth,
        menuRef
      };
      },
}
</script>
<style scoped>
@import url("https://use.typekit.net/yry4xnf.css");
.nav-pad{
  padding-top: 8px;
  padding-bottom: 8px;
}
.button{
  margin-top: 0rem;
}
.navbar11_menu {
    transition: transform 4s ease, max-height 4s ease;
    max-height: 0;
    overflow: hidden;
    position: fixed;
    top: 3.7rem;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }
  .show-nav{
    display:flex !important;
  }

  .nav-open {
    max-height: 400px;
    background-color: #0f0f0f;
    transform: translateY(0%); 
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
    position: fixed !important;
    top: 3.7rem;
    left: 0;  
    right: 0;  
  }
  
  .mobile-link{
    margin-left: 0;
  }

  .nav-close {
    max-height: 0;
    z-index: 0;
    overflow: hidden;
    transform: translateY(-100%);
  }
  .nav-links-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .navbar11_menu-buttons {
    width: 100%; 
    flex-grow: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobile-button {
    width: 100%;
    text-align: center;
    padding: 1rem; 
    font-size: 1.2rem;
  }
  
</style>