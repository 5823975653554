<template>
  <div id="voteBlock" class="decision">
    <h3 class="cta">WHAT HAPPENS NEXT?</h3>
    <div class="cta-subtext">
      Anyone who has an
      <a href="https://awardble.gg/" target="_blank" class="body-link">Awardable Account</a>
      can vote for tomorrow's outcome. Make your selection then sign in with Awardable to lock it in.
    </div>
    <div
      v-for="(option, index) in options"
      :key="option.id"
      class="option-container"
      :class="{
        'option-selected': selectedOptionId === option.id,
        'disabled-option': hasVoted && selectedOptionId !== option.id
      }"
      @click="selectOption(option.id)"
    >
      <div id="voteCount" class="chapter-number">{{ String.fromCharCode(index + 65) }}</div>
      <p class="paragraph">{{ option.text }}</p>
    </div>
    <button
      id="signIn"
      href="https://awrd.gg"
      @click="loginWithAwardable"
      :disabled="!selectedOptionId || hasVoted || processing"
      target="_blank"
      class="login link-block-2 w-inline-block"
    >
      <img src="@/assets/images/awardable_icon.svg" loading="lazy" alt="" class="awrd-logo">
      <div class="sign-in-button">
        {{ hasVoted ? 'You voted!' : processing ? 'Processing...' : 'Sign in to Vote' }}
      </div>
    </button>
    <!-- <div v-if="!isError" class="powered-by">{{ displayMessage }}</div> -->
    <div v-if="isError" class="error-message">{{ displayMessage }}</div>
  </div>
</template>

<script>
import { ref, toRefs, watch } from 'vue';

export default {
  name: 'VoteSection',
  props: {
    options: {
      type: Array,
      required: true,
    },
    chapter: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const selectedOptionId = ref(null)
    const hasVoted = ref(false)
    const errorMessage = ref(null)
    const displayMessage = ref(null)
    const isError = ref(false)
    const processing = ref(false)
    const voteTally = ref({})
    const totalVotes = ref(0)
    const chapter = 132
    const propVals = toRefs(props)
    console.debug(propVals.value)
    let timeoutId = null

    watch(chapter, () => {
      checkVotedCookie()
    }, {flush: 'sync'})

    const fetchVoteTally = () => {
      fetch('https://warriors-zerg.s3.amazonaws.com/vote-tally.json')
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
          }
          return response.json()
        })
        .then(data => {
          voteTally.value = data
          totalVotes.value = Object.values(voteTally.value['1']).reduce((a, b) => a + b, 0)
          if (totalVotes.value > 1) {
            displayMessage.value = `Voted on by ${totalVotes.value} users`
          } else {
            displayMessage.value = `Voted on by ${totalVotes.value} user`
          }
        })
        .catch(error => {
          console.error('Error:', error)
        });
    };
    fetchVoteTally()

    const showMessage = (message, duration, error = false) => {
      if (timeoutId) clearTimeout(timeoutId)
      displayMessage.value = message
      isError.value = error
      timeoutId = setTimeout(() => {
        if (totalVotes.value > 1) {
          displayMessage.value = `Voted on by ${totalVotes.value} users`;
        } else {
          displayMessage.value = `Voted on by ${totalVotes.value} user`;
        }
        isError.value = false
      }, duration)
    };

    const selectOption = optionId => {
      if (hasVoted.value) {
        return
      }
      selectedOptionId.value = optionId
    };

    const loginWithAwardable = () => {
      processing.value = true
      const currentURL = window.location.href
      const popupWindow = window.open(
        `https://awrd.gg/login?redirect_uri=${encodeURIComponent(
          currentURL
        )}&scope=APP_KEY%20API_USERNAME`,
        '_blank',
        'width=500,height=500'
      )

      const checkPopup = setInterval(() => {
        if (!popupWindow || popupWindow.closed || popupWindow.closed === undefined) {
          clearInterval(checkPopup)
        } else {
          try {
            const url = popupWindow.location.href
            const searchParams = new URLSearchParams(new URL(url).search)
            const APP_KEY = searchParams.get('APP_KEY')
            const API_USERNAME = searchParams.get('API_USERNAME')
            if (APP_KEY && API_USERNAME) {
              clearInterval(checkPopup)
              sessionStorage.setItem('APP_KEY', APP_KEY)
              sessionStorage.setItem('API_USERNAME', API_USERNAME)
              triggerVote()
              popupWindow.close()
            }
          } catch (error) {
            console.log('Unable to access popup window, probably due to cross-origin issues')
          }
        }
      }, 1000);
    };

    const triggerVote = async () => {
      const APP_KEY = sessionStorage.getItem('APP_KEY')
      const API_USERNAME = sessionStorage.getItem('API_USERNAME')
      const parts = APP_KEY.split(':-:')
      if(!APP_KEY || APP_KEY === undefined || APP_KEY === 'undefined' || !API_USERNAME || API_USERNAME === undefined || API_USERNAME === 'undefined'){
        errorMessage.value = 'There was an error processing your vote. Please try again.'
        showMessage(errorMessage.value, 10000, true)
        processing.value = false
      }
      // if (parts.length  === 2){
      //   const warriors_seed = ['0xcbf563b440f6aeb0049cc048aaeed8a0']
      //   const body = {
      //       seed_ids: warriors_seed,
      //       address: '0x0000000000000000000000000000000000000000',
      //       APP_KEY: parts[1],
      //       API_TOKEN: parts[0],
      //       request: 'simulate'
      //   }
      //   const response = await fetch('https://vr22n8po8k.execute-api.us-east-1.amazonaws.com/badges/allow/generate', {
      //       method: 'POST',
      //       headers: {
      //           'Content-Type': 'application/json',
      //       },
      //       body: JSON.stringify(body),
      //   })
      //   const resp_data = await response.json()
      //   if (!resp_data.body.inputs['0xcbf563b440f6aeb0049cc048aaeed8a0'].details['5886']) {
      //     processing.value = false
      //     isError.value = true
      //     displayMessage.value = 'You do not own a Warrior Talisman.'
      //     return
      //   }
      // }

      const url = 'https://pvkb44af3c.execute-api.us-east-1.amazonaws.com/default/LogVote'
      const data = {
        appKey: APP_KEY,
        userId: API_USERNAME,
        api_token: parts[0],
        voteSelection: selectedOptionId.value,
        chapterNumber: chapter,
      };

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          if (data.statusCode == 200) {
            hasVoted.value = true
            totalVotes.value++
            setVotedCookie()
            if (totalVotes.value > 1) {
              displayMessage.value = `Voted on by ${totalVotes.value} users`
            } else {
              displayMessage.value = `Voted on by ${totalVotes.value} user`
            }
          } else if (data.statusCode == 400) {
            hasVoted.value = true
            setVotedCookie()
            errorMessage.value = 'You have already voted for this chapter.'
            showMessage(errorMessage.value, 10000, true)
          } else {
            errorMessage.value = 'There was an error processing your vote.'
            showMessage(errorMessage.value, 10000, true)
            processing.value = false
          }
          
        })
        .catch(error => {
          console.error('Error:', error)
        });
    };

    const setVotedCookie = () => {
      const expires = new Date()
      expires.setFullYear(expires.getFullYear() + 1)
      const selectedOption = selectedOptionId.value
      document.cookie = `hasVoted${chapter}=true; expires=${expires.toUTCString()}; path=/`
      document.cookie = `selectedOption${chapter}=${selectedOption}; expires=${expires.toUTCString()}; path=/`
    };

    const checkVotedCookie = () => {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim()
        if (cookie.startsWith(`hasVoted${chapter}=`)) {
          hasVoted.value = true
        }
        if (cookie.startsWith(`selectedOption${chapter}=`)) {
          const selectedOption = parseInt(cookie.split('=')[1])
          selectedOptionId.value = selectedOption
        }
      }
    };

    checkVotedCookie()

    return {
      selectedOptionId,
      selectOption,
      loginWithAwardable,
      hasVoted,
      errorMessage,
      displayMessage,
      isError,
      processing,
      totalVotes,
    };
  },
};
</script>

<style>
.login[disabled] {
  background-color: #999;
  /* cursor: none; */
}
.disabled-option {
  border: 1px solid rgba(255, 255, 255, 0.25);
  pointer-events: none;
}

.disabled-option .chapter-number {
  color: rgba(255, 255, 255, 0.5);
}

.disabled-option .paragraph {
  color: rgba(255, 255, 255, 0.5);
}
</style>
