<template>
    <div id="app">
      <BookController series="Two" />
    </div>
  </template>
  
  <script>
import BookController from '../components/BookController.vue'
  export default {
    name: 'App',
    components: {
        BookController
    },
    setup(){}
  }
  </script>
  
  <style>
  .html{
    background-color: #0f0f0f;
  }
  </style>
  