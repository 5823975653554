<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  setup(){}
}
</script>

<style>
.html{
  background-color: #0f0f0f;
}
</style>
