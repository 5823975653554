<template>
    <footer class="footer5_component">
    <div class="padding-global">
      <div class="container-large">
        <div class="line-divider"></div>
        <div class="padding-section-small">
          <div class="footer5_bottom-wrapper">
            <div class="w-layout-grid footer5_legal-list">
              <div id="w-node-_64f48cfb-aac3-4a17-dbf9-a6ebe9462630-e9462628" class="footer5_credit-text">© 2023 ZERG LLC. All rights reserved.</div>
              <a href="https://awardable.gg/legal#terms" class="footer5_legal-link">LEGAL</a>
            </div>
            <div class="w-layout-grid footer5_social-icons">
              <a href="https://twitter.com/zergstudio" target="_blank" class="footer5_social-link w-inline-block">
                <div class="icon-embed-xsmall w-embed"><svg width="100%" height="100%" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.1761 4H19.9362L13.9061 10.7774L21 20H15.4456L11.0951 14.4066L6.11723 20H3.35544L9.80517 12.7508L3 4H8.69545L12.6279 9.11262L17.1761 4ZM16.2073 18.3754H17.7368L7.86441 5.53928H6.2232L16.2073 18.3754Z" fill="CurrentColor"></path>
                  </svg></div>
              </a>
              <a href="https://discord.gg/zergstudio" target="_blank" class="footer5_social-link w-inline-block">
                <div class="icon-embed-xsmall w-embed"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--carbon" width="100%" height="100%" preserveaspectratio="xMidYMid meet" viewbox="0 0 32 32">
                    <path fill="currentColor" d="M25.7 7.1C23.9 6.3 22 5.7 20 5.3h-.1c-.2.4-.5 1-.7 1.5c-2.2-.3-4.3-.3-6.4 0c-.2-.5-.5-1-.7-1.5H12c-2 .3-3.9.9-5.7 1.8C2.7 12.5 1.7 17.8 2.2 23v.1c2.4 1.8 4.7 2.8 7 3.5h.1c.5-.7 1-1.5 1.4-2.3v-.1c-.8-.3-1.5-.6-2.2-1c-.1 0-.1-.1 0-.1c.1-.1.3-.2.4-.3H9c4.6 2.1 9.5 2.1 14.1 0h.1c.1.1.3.2.4.3c.1 0 0 .1 0 .1c-.7.4-1.4.8-2.2 1c0 0-.1.1 0 .1c.4.8.9 1.6 1.4 2.3h.1c2.3-.7 4.6-1.8 7-3.5V23c.6-6-1-11.2-4.2-15.9zM11.4 19.9c-1.4 0-2.5-1.3-2.5-2.8s1.1-2.8 2.5-2.8s2.5 1.3 2.5 2.8c0 1.5-1.1 2.8-2.5 2.8zm9.3 0c-1.4 0-2.5-1.3-2.5-2.8s1.1-2.8 2.5-2.8s2.5 1.3 2.5 2.8c0 1.5-1.1 2.8-2.5 2.8z"></path>
                  </svg></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<style scoped>
    @import url("https://use.typekit.net/yry4xnf.css");
    .iconify--carbon path {
  transform: scale(0.8);
  transform-origin: 0%; 
}
</style>