<template>
  <section class="wf-section">
    <div class="w-layout-blockcontainer container w-container">
      <div v-for="(chapter, index) in chapters" :key="index" :id="'chapter-'+(index + 1)" class="hero-top">
        <!-- <img v-if="chapter.image" :src="chapter.image" loading="lazy" sizes="(max-width: 767px) 100vw, 600px" :alt="chapter.title" class="image-3"> -->
        <div class="div-block-2">
          <div class="chapter-number">{{ chapter.number }}</div>
          <div>
            <h2 class="heading-2">{{ formatTitle(chapter.title) }}</h2>
            <div v-if="chapter.votes > 0" class="subheading">Outcome decided by {{ chapter.votes }} people</div>
          </div>
        </div>
        <p class="story-body" v-html="chapter.body"></p>
      </div>
      <VoteSection v-if="series == 'Two'" :options="options" :chapter="chapters.length"></VoteSection>
    </div>
  </section>
</template>

<script>
//   import { ref, defineProps } from 'vue'
import VoteSection from '@/components/VoteSection.vue'

export default {
  name: 'Chapters',
  components: {
    VoteSection
  },
  props: {
    chapters: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    series: String
  },
  methods: {
    getImageUrl(path) {
      return window.location.href + path;
    },
    formatTitle(title) {
      return title.replace(/Chapter\s\d+:\s/, '');
    }
  }
}
</script>

<style>
</style>
