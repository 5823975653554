<template>
  <div>
    <Navbar></Navbar>
    <!-- <Header></Header> -->
    <Chapters :series="series" :chapters="chapters" :options="options"></Chapters>
    <div id="chapterid-selector" class="sticky-chapter-selector-container">
      <select id="chapter-dropdown" class="chapter-selector">
      </select>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import Chapters from '@/components/Chapters.vue'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
// import Header from '@/components/Header.vue'

export default {
  name: 'BookController',
  components: {
    Chapters,
    Navbar,
    Footer
    // Header
  },
  props: {
    series: String
  },
  setup(props) {
    console.debug("series", props.series)
    const chapters = ref([])
    const options = ref([])

    const fetchChapters = async () => {
      const response = await fetch(`https://warriors-zerg.s3.amazonaws.com/book/chapters${props.series}.json`)
      if (!response.ok) {
        console.error("Failed to fetch chapters data:", response.status, response.statusText)
        return
      }
      const chaptersData = await response.json()
      chapters.value = chaptersData
      options.value = chaptersData[chaptersData.length - 1].options
    }

    const updateChapterSelector = () => {
      // Find all chapter elements
      let chapters = document.querySelectorAll('div[id^="chapter-"]');
      let dropdown = document.getElementById('chapter-dropdown');
      // let chapterSelector = document.getElementById('chapterid-selector');
      // Add all chapters to the dropdown
      chapters.forEach((chapter, index) => {
        let option = document.createElement('option');
        option.value = chapter.id;
        option.textContent = 'Chapter ' + (index + 1);
        dropdown.appendChild(option);
      });
      
      // Add event listener for changing chapters
      dropdown.addEventListener('change', function(e) {
        let chapterId = e.target.value;
        let chapter = document.getElementById(chapterId);
        chapter.scrollIntoView();
      });

      // // Function to check the scroll position
      let checkScroll = function() {
      // Get the current scroll position
        let scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

        // Loop through each chapter
        chapters.forEach((chapter, index) => {
          // Get the top and bottom position of the chapter
          let top = chapter.offsetTop;
          let bottom = top + chapter.offsetHeight;

          // Check if the scroll position is within the bounds of the chapter
          if (scrollPosition > top && scrollPosition < bottom){
            console.debug(index)

            // If it is, update the dropdown's value to the current chapter
            dropdown.value = chapter.id;
          }
        });
      };
      
      // // Check the scroll position initially
      checkScroll();
      // chapterSelector.style.display = 'block';
      // And check it again every time the user scrolls
      window.addEventListener('scroll', checkScroll);
    }

    onMounted(() => {
      window.document.title = 'WAR - A perpetual novel'
      window.document.description = 'The origin story of The Warriors, written by The Hideout.'
      fetchChapters().then(() => updateChapterSelector())
    })

    return {
      chapters,
      options
    }
  }
}
</script>

<style scoped>
  .chapter-selector{
    border: 0px;
    color: #fff;
    -moz-appearance: none;
    -webkit-appearance: none;
    padding: .25rem .5rem;
    text-indent: 1px;
    text-transform: none;
    background: hsla(0,0%,100%,.1254901960784313);
  }
  .chapter-selector {
    border: 0px;
    color: #fff;
    -moz-appearance: none;
    -webkit-appearance: none;
    padding: .25rem .5rem;
    text-indent: 1px;
    text-transform: none;
    background: hsla(0, 0%, 100%, .1254901960784313);
  }
  .sticky-chapter-selector-container {
    position: sticky;
    left: 50%;
    right: 50%;
    bottom: 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    padding: .5rem;
  }
  @media (max-width: 768px) {
    .sticky-chapter-selector-container {
      position: sticky;
      left: 50%;
      right: 50%;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: black;
      padding: .5rem;
    }
  }
</style>
