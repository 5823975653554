<template>
    <div id="app">
      <router-view />
    </div>
  </template>
  
  <script>
  export default {
    name: 'App',
    components: {},
    mounted(){
        window.location.href='https://zerg.studio/lore'
    },
    setup(){}
  }
  </script>
  
  <style>
  .html{
    background-color: #0f0f0f;
  }
  </style>
  