import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue'
import SeriesOne from '../views/SeriesOne.vue'
import SeriesTwo from '../views/SeriesTwo.vue'

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index
    },
    {
        path: '/1',
        name: 'SeriesOne',
        component: SeriesOne
    },
    {
        path: '/2',
        name: 'SeriesTwo',
        component: SeriesTwo
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router